import React from "react";
import * as classes from "./CarouselController.module.scss";
import ControlButton from "./ControlButton/ControlButton";
import { CardCarouselItems } from "./CardCarouselItems/CardCarousel";
import { graphql, useStaticQuery } from "gatsby";

const CardsCarousel = () => {
  const projectData = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy {
        nodes {
          title
        }
      }
    }
  `);

  // const carouselLength = projectData.allContentfulCaseStudy.nodes.length;
  const carouselLength = 5;
  const [active, setActive] = React.useState(1);
  const [arrowLeftDisabled, setArrowLeftDisabled] = React.useState(true);
  const [arrowRightDisabled, setArrowRightDisabled] = React.useState(false);

  function arrowClickLeft() {
    if (active === 1) return;
    const newActive = active - 1;
    setActive(newActive);
    if (newActive === 1) setArrowLeftDisabled(true);
    setArrowRightDisabled(false);
  }
  function arrowClickRight() {
    if (active === carouselLength) return;
    const newActive = active + 1;
    setActive(newActive);
    if (newActive === carouselLength) setArrowRightDisabled(true);
    setArrowLeftDisabled(false);
  }

  return (
    <div className={classes.carouselContainer}>
      <ControlButton
        clicked={arrowClickLeft}
        disabled={arrowLeftDisabled}
        variant="left"
        context="cards"
      />
      <CardCarouselItems active={active} move={active} />
      <ControlButton
        clicked={arrowClickRight}
        disabled={arrowRightDisabled}
        variant="right"
        context="cards"
      />
    </div>
  );
};
export default CardsCarousel;
