// extracted by mini-css-extract-plugin
export var cards = "index-module--cards--xjoYo";
export var cardsContainer = "index-module--cards-container--iWqjp";
export var contactIconsContainer = "index-module--contact-icons-container--L6tYv";
export var contactSocialIcon = "index-module--contact-social-icon--uK0bn";
export var contactSocialIconInstagram = "index-module--contact-social-icon-instagram--akg6M";
export var contactSocialIconLinkedIn = "index-module--contact-social-icon-linkedIn--8cHEy";
export var contactSocialIconTwitter = "index-module--contact-social-icon-twitter--FIcsv";
export var dissolveIn = "index-module--dissolveIn--p7Sfg";
export var header = "index-module--header--Un-S0";
export var headerContainer = "index-module--header-container--36IN2";
export var headerContent = "index-module--header-content--Rt-z4";
export var headerHeading = "index-module--header-heading--0dAcY";
export var headerSubtitle = "index-module--header-subtitle--mBYZa";
export var homepageHeading2 = "index-module--homepage-heading2--5Siks";
export var homepageSubtitle = "index-module--homepage-subtitle--+S3Rk";
export var linkText = "index-module--link-text--Hp2At";
export var projectWork = "index-module--project-work--6wm1j";
export var sectionContainer = "index-module--section-container--KWIyO";
export var sectionDescription = "index-module--section-description--K+vTM";
export var siteSection = "index-module--site-section--zHde4";