import * as React from "react";
import * as classes from "../SCSS/index.module.scss";

import "../SCSS/global.scss";

import { CardBase, HomePageCardContent } from "../components/Cards/Card";
import HomepageLayout from "../components/Layout/HomepageLayout";
import CardsCarousel from "../components/CardsCarousel/CarouselController";
import { aboutRoute, workRoute, blogRoute } from "../NavItems";

import "@fontsource/open-sans";
import "@fontsource/titillium-web";

const IndexPage = () => {
  const handleRoute = (route) => {
    document.startViewTransition(() => {
      window.location.assign(route);
    });
  };
  return (
    <HomepageLayout
      pageTitle="My personal product design site"
      pageDescription="I'm a product designer from Ireland. Check out some of my recent
              work or get to know me a bit better."
    >
      <header className={classes.header}>
        <div className={classes.headerContainer}>
          <div className={classes.headerContent}>
            <h1 className={classes.headerHeading}>Hi! I'm Dan.</h1>
            <p
              className={[
                classes.homepageSubtitle,
                classes.headerSubtitle,
              ].join(" ")}
            >
              I'm a product designer from Ireland. Check out some of my recent
              work or get to know me a bit better.
            </p>

            <div className={classes.cards}>
              <div className={classes.cardsContainer}>
                <CardBase toLink={`${workRoute}`}>
                  <HomePageCardContent
                    cardLabel="Case Studies"
                    cardDescription="Check out some of the recent projects I've worked on."
                    icon="work"
                  />
                </CardBase>
                <CardBase toLink={`${aboutRoute}`}>
                  <HomePageCardContent
                    cardLabel="About Me"
                    cardDescription="Learn a bit more about me, my story and my hobbies."
                    icon="user"
                  />
                </CardBase>

                <CardBase toLink={`${blogRoute}`}>
                  <HomePageCardContent
                    cardLabel="Design Blog"
                    cardDescription="Read my posts on product design &amp; front-end development."
                    icon="book"
                  />
                </CardBase>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className={classes.projectWork}>
        <h2 className={classes.homepageHeading2}>Recent Bits</h2>
        <CardsCarousel />
      </section>
      <section className={classes.sectionContainer}>
        <h2 className={classes.homepageHeading2}>About My Site</h2>
        <div className={classes.siteSection}>
          <div className={classes.sectionDescription}>
            <div>
              <p className={classes.homepageSubtitle}>
                I designed my site in Figma &amp; developed it with front-end
                technologies, including React, SASS, Contentful CMS, &amp;
                Gatsby.js with its GraphQL-powered data layer. I wrote about the{" "}
                <a
                  href="/case-studies/my-personal-site"
                  onClick={handleRoute}
                  className={classes.linkText}
                >
                  design &amp; development process.
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.sectionContainer}>
        <h2 className={classes.homepageHeading2}>Get In Touch!</h2>
        <div className={classes.sectionDescription}>
          <p className={classes.homepageSubtitle}>
            Want to chat or have some feedback on my site? Feel free to reach
            out!
          </p>
        </div>
        <div className={classes.contactIconsContainer}>
          <div>
            <a target="blank" href="http://www.twitter.com/dansweeeeeney">
              <svg
                alt="Link to Twitter"
                className={[
                  classes.contactSocialIcon,
                  classes.contactSocialIconTwitter,
                ].join(" ")}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 32 32"
              >
                <title>Link to Twitter</title>
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
          </div>
          <div>
            <a target="blank" href="https://www.instagram.com/dansweeeeeney">
              <svg
                alt="Link to Instagram"
                className={[
                  classes.contactSocialIcon,
                  classes.contactSocialIconInstagram,
                ].join(" ")}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 32 32"
              >
                <title>Link to Instagram</title>
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
          </div>
          <div>
            <a target="blank" href="http://www.linkedin.com/in/dsweeney93">
              <svg
                alt="Link to LinkedIn"
                className={[
                  classes.contactSocialIcon,
                  classes.contactSocialIconLinkedIn,
                ].join(" ")}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 32 32"
              >
                <title>Link to LinkedIn</title>
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
            </a>
          </div>
        </div>
      </section>
    </HomepageLayout>
  );
};

export default IndexPage;
