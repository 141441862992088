import React from "react";
import TopNav from "../Navigation/TopNav/TopNav";
import Footer from "../Navigation/Footer/Footer";
import Helmet from "react-helmet";
import Favicon from "../../images/favicon.ico";
import * as classes from "./Layout.module.scss";
import "@fontsource/open-sans";
import "@fontsource/titillium-web";
import "@fontsource/inter";

const Layout = (props) => {
  return (
    <>
      <TopNav selected={props.selected} homepage />
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="all" />
        <meta name="description" content={props.pageDescription} />
        <title>{props.pageTitle}</title>
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
      </Helmet>
      <main
        className={[classes.mainContainer, classes.homepageContainer].join(" ")}
      >
        {props.children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
