import React from "react";
import * as classes from "./CardCarousel.module.scss";
import {
  CardBase,
  CarouselCardContent,
  CarouselCardWrapper,
} from "../../Cards/Card";
import { graphql, useStaticQuery } from "gatsby";
import { blogRoute, workRoute } from "../../../NavItems";

export const CardCarouselItems = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy(limit: 5, sort: { fields: endDate, order: DESC }) {
        nodes {
          # id
          title
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          topics {
            topics
          }
          slug
          lastMajorUpdate
        }
      }
      allContentfulPost(
        sort: { fields: datePublished, order: DESC }
        limit: 5
      ) {
        nodes {
          # id
          title
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          postDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          topics {
            topics
          }
          slug
          lastMajorUpdate
        }
      }
    }
  `);

  const mergedData = [
    ...data.allContentfulCaseStudy.nodes,
    ...data.allContentfulPost.nodes,
  ]
    .sort((a, b) => {
      // const aDate = new Date(a.datePublished ? a.datePublished : a.startDate);
      // const bDate = new Date(b.datePublished ? b.datePublished : b.startDate);
      return (
        new Date(a.lastMajorUpdate) - new Date(b.lastMajorUpdate)
        // new Date(b.datePublished ? b.datePublished : b.startDate)
      );
    })
    .reverse()
    .splice(0, 5);

  return (
    <div className={classes.cardContainer}>
      {mergedData.map((item, i) =>
        item.sys.contentType.sys.id === "caseStudy" ? (
          <CarouselCardWrapper
            move={props.move}
            active={props.active === i + 1}
            key={i + 1}
          >
            <CardBase toLink={`${workRoute}/${item.slug}`}>
              <CarouselCardContent
                title={item.title}
                description={
                  item.description.childMarkdownRemark.rawMarkdownBody
                }
                key={i + 1}
                areas={item.topics.topics}
                workType="Case Study"
              />
            </CardBase>
          </CarouselCardWrapper>
        ) : (
          <CarouselCardWrapper
            move={props.move}
            active={props.active === i + 1}
            key={i + 1}
          >
            <CardBase toLink={`${blogRoute}/${item.slug}`}>
              <CarouselCardContent
                title={item.title}
                description={
                  item.postDescription.childMarkdownRemark.rawMarkdownBody
                }
                key={i + 1}
                areas={item.topics.topics}
                workType="Blog Post"
              />
            </CardBase>
          </CarouselCardWrapper>
        )
      )}
    </div>
  );
};
